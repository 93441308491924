body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	
	/* background: linear-gradient(#ff5e62, #ff9966) fixed; */
	background-color: white;
	/* color: white; */
	/*max-width: 574px;*/
  margin: auto;
	overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	/* overflow: auto; */
	/*padding-bottom: 20vh;*/
}

/* AntD Overrides */
.ant-layout {
	background: none;
}
/* Remove border on inactive sider menu */
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
	border: none;
}
.ant-layout-footer {
	background: none;
}

.ant-card {
	border-radius: 20px;
}

.ant-btn {
	/*color: white;*/
  border-radius: 5px;
  border: none;
	box-shadow: 5px 10px 25px #00000015;

	transition: transform .1s ease-out;
}
.ant-btn:hover {
	transform: scale(1.05);
}

.ant-card-body {
	padding: 0;
}
.ant-card {
	overflow: hidden;
}

.ant-modal {
	width: 100% !important;
	max-width: 574px;
	border-radius: 20px;
}

.ant-modal-content {
	border-radius: 20px;
	overflow: hidden;
	max-height: 100vh;
	margin: 5vh 0 5vh 0;
}

.ant-input, .ant-input-affix-wrapper {
	/*border: none;*/
	border-radius: 5px;
	/* white-space: pre; */
	/* box-shadow: 5px 10px 25px #00000010; */
}
.ant-input:focus, .ant-input-focused, .ant-input-group:focus {
	box-shadow: 5px 10px 25px #00000010;
}
.ant-input-group-wrapper {
	border-radius: 5px;
	overflow: hidden;
	transition: box-shadow .33s ease-in-out;
}
.ant-input-group-wrapper:focus-within,
.ant-input-affix-wrapper-focused {
	box-shadow: 5px 10px 25px #00000010;
}

.ant-input-group-addon, .ant-input-group-addon:first-child {
	border: none;
}

.ant-upload.ant-upload-select-picture-card {
	width: 100%;
	height: 150px;
	margin-right: 0;
	margin-bottom: 0;
	border: none;
}
.ant-upload {
	width: 100%;
	height: 100%;
}

.ant-input-group-addon {
	background-color: var(--ant-primary-color-disabled);
	color: #FFF;
}

/* Global Styles */
* {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
	height: 8px;
	padding: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000015;
  border-radius: 20px;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

/* Custom Classes */ 
.button-group {
	white-space: pre;
	transition: opacity .1s ease-in-out;
}
.button-group .ant-btn {
	border-radius: 0;
}
.button-group .ant-btn:hover {
	z-index: 1;
}
.button-group .ant-btn:first-child {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.button-group .ant-btn:last-child {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.gradient-overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
	pointer-events: none;
}