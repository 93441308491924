#doc-iframe {
	width: 100%;
	margin: auto;
	height: 100vh;

	border: none;
}

#rest-doc {
	padding: 20px; 
}

#rest-doc code {
	color: var(--ant-primary-color);
}

#rest-doc pre {
	max-height: 576px;
	background: #eea90610;
	border-radius: 10px;
	border: 1px solid #eea90620;
	overflow: auto;
}

#rest-doc .code-container {
	margin: 20px;
}

#rest-doc .api-container {
	border: none;
	background-color: var(--ant-primary-1);
	border-radius: 0 20px 20px 0;
	border-left: 3px solid var(--ant-primary-color);
	/*border: 1px solid var(--ant-primary-color);*/
}

#rest-doc .ant-collapse > .ant-collapse-item,
#rest-doc .ant-collapse-content {
	border-color: rgba(0, 0, 0, 0.05);
	/*border-color: var(--ant-primary-color);*/
}

#rest-doc h4 {
	color: var(--ant-primary-color);
}

#rest-doc .ant-btn {
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, 0.05);
}