#tech-doc {
	min-height: 90vh;
	padding: 20px;
}
#tech-doc img {
  border-radius: 20px;
	transition: transform .1s ease-in-out, box-shadow .1s ease-in-out;
	/*padding: 10px;*/
}
#tech-doc img:hover {
	transform: scale(1.05);
  box-shadow: 5px 10px 25px #00000010;
}



#tech-doc code { 
	/*background: hsl(220, 80%, 90%); */
}

#tech-doc pre {
	background: #eea90610;
	border-radius: 10px;
	border: 1px solid #eea90620;
	overflow: auto;
	margin-left: 20px;
}

#tech-doc .code-container {
	margin: 20px;
}

#tech-doc code {
	color: var(--ant-primary-color);
}

#tech-doc h2, 
#tech-doc h3 { 
	color: var(--ant-primary-color);
}
#tech-doc h2 {
	margin-top: 100px;
	/*color: white;*/
}
#tech-doc h2 {
	background-color: var(--ant-primary-1);
	border-radius: 0 20px 20px 0;
	padding: 20px;

	border-left: 3px solid var(--ant-primary-color);
}

#tech-doc p {
	margin-left: 20px;
}

#tech-doc .ant-btn {
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, 0.05);
}

#tech-doc table {
	margin: 20px;
}
#tech-doc td, #tech-doc th {
	padding: 10px;
	border: 1px solid var(--ant-primary-color);
}
